// import $ from 'jquery';
// window.$ = $;
// window.jQuery = $;
import 'slick-carousel/slick/slick';
import 'slick-carousel/slick/slick.scss';

(function($){

    const UBSliders = {
        init: () => {

            jQuery('.slick-slider').each(function(idx, slider){

                let slideOptions = {
                    slidesToShow: jQuery(slider).data('slidestoshow') ? parseInt(jQuery(slider).data('slidestoshow')) : 1,
                    slidesToScroll: jQuery(slider).data('slidestoscroll') ? parseInt(jQuery(slider).data('slidestoscroll')) : 1,
                    autoplay: jQuery(slider).data('autoplay') ? parseInt(jQuery(slider).data('autoplay')) : 0,
                    autoplaySpeed: jQuery(slider).data('autoplayspeed') ? parseInt(jQuery(slider).data('autoplayspeed')) : 0,
                    arrows: jQuery(slider).data('arrows') ? parseInt(jQuery(slider).data('arrows')) : 0,
                    dots: jQuery(slider).data('dots') ? parseInt(jQuery(slider).data('dots')) : 0,
                    infinite: jQuery(slider).data('infinite') ? parseInt(jQuery(slider).data('infinite')) : 1,
                    speed: jQuery(slider).data('speed') ? parseInt(jQuery(slider).data('speed')) : 1000,
                    adaptiveheight: jQuery(slider).data('adaptiveheight') ? parseInt(jQuery(slider).data('adaptiveheight')) : 0,
                    centerMode: jQuery(slider).data('centermode') ? parseInt(jQuery(slider).data('centermode')) : 0
                };

                jQuery(slider).slick(slideOptions);

            });

        }
    }

    jQuery(function($){
        UBSliders.init();
    });

})(jQuery);